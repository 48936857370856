import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  style: {
    "margin-top": "80px"
  }
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "flex-direction": "column",
    "align-items": "flex-start"
  }
};
const _hoisted_3 = {
  key: 0,
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_4 = {
  key: 1,
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_5 = {
  style: {
    "font-size": "32px",
    "color": "#95d475"
  }
};
const _hoisted_6 = {
  key: 2,
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_7 = {
  style: {
    "font-size": "30px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 22,
      sm: 22,
      md: 10,
      lg: 10,
      xl: 10
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "padding": "50px 20px",
          "display": "flex",
          "flex-direction": "column",
          "align-items": "center"
        }
      }, {
        default: _withCtx(() => [_cache[3] || (_cache[3] = _createElementVNode("div", {
          style: {
            "margin-bottom": "20px",
            "font-size": "26px",
            "font-weight": "500"
          }
        }, "AMC8 入营评估", -1)), _createElementVNode("div", _hoisted_2, [$setup.record ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "评估结果：", -1)), _createElementVNode("span", {
          class: _normalizeClass({
            isYes: $setup.record.record.passed,
            isNo: !$setup.record.record.passed
          }),
          style: {
            "font-size": "36px"
          }
        }, _toDisplayString($setup.record.record.passed ? '通过' : '未通过'), 3)])) : _createCommentVNode("", true), $setup.record ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "得分：", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString($setup.record.record.score), 1)])) : _createCommentVNode("", true), $setup.record ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("span", null, "总分：", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString($setup.record.score), 1)])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}