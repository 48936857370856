import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from "./store/index";
import { userStore } from './store/index'
import { createPinia } from 'pinia'
// import ElementPlus from 'element-plus'
// import './utils/mathjax.js'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import 'mathjax/es5/tex-svg' // 使用 tex-svg.js
// import "mathjax/es5/tex-mml-chtml"; // 使用 tex-mml-chtml
// import getObsUrl from './utils/obsUrl.js'
import * as dayjs from 'dayjs'
import 'element-plus/dist/index.css'
import { getUserInfo } from '@/utils/auth'

window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

let store = null
router.beforeEach((to, _, next) => {
  store ||= userStore()

  let arg = null
  const userInfo = getUserInfo()
  if (userInfo) {
    store.setInfo(userInfo)
    if (to.path === '/login') arg = { path: '/' }
  } else if (!['/login', '/'].includes(to.path)) arg = { path: '/' }

  document.title = to.meta.title || '数学超哥'
  store.setPageTitle(document.title)

  next(arg)
})

const pinia = createPinia()

const app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
// app.config.globalProperties.$getObsUrl = getObsUrl
app.use(pinia) // 使用 Store
// app.use(ElementPlus) // 使用ElementPlus
app.use(router) // 使用Vue Router
// app.use(store); // 使用 Store

// app.use(dayjs); // 使用Vuex Store

// app.provide('$globalVariable', mathjax)

app.mount('#app') // 挂载Vue应用程序到指定的元素
