import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  style: {
    "margin-top": "80px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 22,
      sm: 22,
      md: 10,
      lg: 10,
      xl: 10
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "padding": "50px 20px",
          "display": "flex",
          "flex-direction": "column",
          "align-items": "center"
        }
      }, {
        default: _withCtx(() => [_cache[10] || (_cache[10] = _createElementVNode("div", {
          style: {
            "margin-bottom": "20px",
            "font-size": "26px",
            "font-weight": "500"
          }
        }, "完善个人信息", -1)), _createVNode(_component_el_form, {
          size: "large",
          model: $setup.ruleForm,
          "label-width": "auto",
          rules: $setup.rules,
          ref: "formRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "姓名",
            prop: "name",
            style: {
              "max-width": "300px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              maxlength: "10",
              modelValue: $setup.ruleForm.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.ruleForm.name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "学校",
            prop: "school",
            style: {
              "width": "300px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              maxlength: "25",
              modelValue: $setup.ruleForm.school,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.ruleForm.school = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "年级",
            prop: "grade",
            style: {
              "width": "300px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: $setup.ruleForm.grade,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.ruleForm.grade = $event),
              placeholder: "请选择年级",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.gradeOptions, (item, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item,
                  value: index
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "性别",
            prop: "gender",
            style: {
              "width": "300px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: $setup.ruleForm.gender,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.ruleForm.gender = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_radio, {
                value: "男"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("男 生")])),
                _: 1
              }), _createVNode(_component_el_radio, {
                value: "女"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("女 生")])),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_button, {
            style: {
              "margin": "20px"
            },
            type: "primary",
            onClick: _cache[4] || (_cache[4] = $event => $setup.submitForm(_ctx.ruleFormRef))
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("保存")])),
            _: 1
          }), _createVNode(_component_el_button, {
            style: {
              "margin": "20px"
            },
            type: "",
            onClick: _cache[5] || (_cache[5] = $event => $setup.backPage())
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("返回")])),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}