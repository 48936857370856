import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../assets/finished.png';
import _imports_1 from '../assets/check.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "itemBox"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  class: "finish",
  alt: ""
};
const _hoisted_5 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "align-items": "center"
  }
};
const _hoisted_6 = {
  style: {
    "text-align": "left"
  }
};
const _hoisted_7 = {
  style: {
    "color": "#FA6400",
    "font-weight": "600",
    "font-size": "14px"
  }
};
const _hoisted_8 = {
  style: {
    "font-weight": "500",
    "font-size": "14px"
  }
};
const _hoisted_9 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_10 = {
  key: 1,
  class: "finishBox"
};
const _hoisted_11 = {
  key: 2
};
const _hoisted_12 = {
  class: "questionTxt",
  id: "quesMath"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = ["onClick", "value"];
const _hoisted_15 = {
  style: {
    "margin-right": "10px",
    "font-weight": "500"
  }
};
const _hoisted_16 = {
  key: 0,
  id: "answerMathIn",
  class: "forBox"
};
const _hoisted_17 = ["src"];
const _hoisted_18 = {
  style: {
    "display": "flex",
    "justify-content": "center"
  },
  class: "cardBig"
};
const _hoisted_19 = {
  style: {
    "color": "#FA6400",
    "font-weight": "600",
    "font-size": "18px"
  }
};
const _hoisted_20 = {
  style: {
    "font-weight": "600",
    "font-size": "20px"
  }
};
const _hoisted_21 = {
  class: "itemBox",
  style: {
    "display": "flex",
    "justify-content": "space-right",
    "align-items": "center"
  }
};
const _hoisted_22 = ["onClick"];
const _hoisted_23 = {
  key: 0,
  src: _imports_0,
  class: "finish",
  alt: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 10,
    class: "marginStyle"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 24,
      sm: 24,
      md: 5,
      class: "cardBig"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "min-height": "800px"
        }
      }, {
        default: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("div", {
          style: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_createElementVNode("span", {
          style: {
            "background": "#409eff",
            "width": "4px",
            "height": "30px"
          }
        }), _createElementVNode("span", {
          style: {
            "font-size": "24px",
            "margin-left": "10px",
            "font-family": "MicrosoftYaHei"
          }
        }, "答题卡")], -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recordArr, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            onClick: $event => $setup.changeIndex(index),
            key: index,
            class: _normalizeClass({
              card_item: true,
              checked: $setup.questionsIndex == index
            })
          }, [item.upBool && item.answer ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(index + 1), 1)], 10, _hoisted_3);
        }), 128))])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      xs: 24,
      sm: 24,
      md: 16,
      style: {
        "position": "relative"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "min-height": "800px"
        }
      }, {
        default: _withCtx(() => [$setup.recordArr.length ? (_openBlock(), _createBlock(_component_el_col, {
          key: 0,
          span: 24,
          class: "cardSmall"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [_cache[3] || (_cache[3] = _createElementVNode("span", {
            style: {
              "font-size": "12px",
              "color": "#a3a3a3"
            }
          }, "剩余时间：", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString($setup.timeNumTxt), 1)]), _createElementVNode("div", null, [_cache[4] || (_cache[4] = _createElementVNode("span", {
            style: {
              "font-size": "12px",
              "color": "#a3a3a3",
              "margin-top": "10px"
            }
          }, "已答题数：", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($setup.recordArr.filter(e => e.answer).length) + "/" + _toDisplayString($setup.recordArr.length), 1)])]), _createElementVNode("div", _hoisted_9, [$setup.questionsIndex < $setup.recordArr.length - 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            size: "small",
            onClick: $setup.nextClick
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("下一题")])),
            _: 1
          }, 8, ["onClick"])) : _createCommentVNode("", true), $setup.questionsIndex == $setup.recordArr.length - 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            type: "primary",
            size: "small",
            onClick: $setup.recordSubmit
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("提交试卷")])),
            _: 1
          }, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
            type: "primary",
            link: "",
            onClick: _cache[0] || (_cache[0] = $event => $setup.dialogVisible = true)
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("答题卡")])),
            _: 1
          })])])]),
          _: 1
        })) : _createCommentVNode("", true), $setup.recordArr.length && $setup.recordArr[$setup.questionsIndex].upBool && $setup.recordArr[$setup.questionsIndex].answer ? (_openBlock(), _createElementBlock("div", _hoisted_10)) : _createCommentVNode("", true), $setup.questionsArr.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, "第" + _toDisplayString($setup.questionsIndex + 1) + "题：" + _toDisplayString($setup.questionsArr[$setup.questionsIndex].content), 1), $setup.questionsArr[$setup.questionsIndex].img ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: {
            "transform": "scale(0.8)",
            "margin-bottom": "-30px",
            "margin-top": "-8px"
          },
          class: "quesImg",
          src: $setup.returnImgUrl($setup.questionsArr[$setup.questionsIndex].img),
          alt: ""
        }, null, 8, _hoisted_13)) : _createCommentVNode("", true), _createElementVNode("div", {
          style: {
            "margin-top": "30px",
            "padding": "0 30px",
            "box-sizing": "border-box"
          },
          class: _normalizeClass({
            imgflexbox: $setup.questionsArr[$setup.questionsIndex].options[0].img ? true : false
          })
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.questionsArr[$setup.questionsIndex].options, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "imgflexin",
            onClick: $event => $setup.upDateQuestions(item.no),
            style: {
              "margin-bottom": "20px"
            },
            size: "large",
            key: index,
            value: item.no
          }, [_createElementVNode("div", {
            class: _normalizeClass({
              forBox: true,
              isCheck: $setup.radio == item.no ? true : false
            })
          }, [_createElementVNode("div", {
            style: _normalizeStyle([`${item.no == $setup.radio ? 'background:#409eff !important;' : 'background: #cccccc;'}`, {
              "width": "18px",
              "height": "18px",
              "border-radius": "100%",
              "position": "relative"
            }])
          }, _cache[8] || (_cache[8] = [_createElementVNode("img", {
            style: {
              "position": "absolute",
              "width": "16px",
              "left": "1px",
              "top": "1px"
            },
            src: _imports_1,
            alt: ""
          }, null, -1)]), 4), _createElementVNode("div", _hoisted_15, "（" + _toDisplayString($setup.radioArr[index]) + "）", 1), item.value ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(item.value), 1)) : _createCommentVNode("", true), item.img ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            style: {
              "max-width": "160px"
            },
            src: $setup.returnImgUrl(item.img),
            alt: ""
          }, null, 8, _hoisted_17)) : _createCommentVNode("", true)], 2)], 8, _hoisted_14);
        }), 128))], 2)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_18, [$setup.recordArr.length && $setup.questionsIndex < $setup.recordArr.length - 1 ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          onClick: $setup.nextClick
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("下一题")])),
          _: 1
        }, 8, ["onClick"])) : _createCommentVNode("", true), $setup.questionsIndex == $setup.recordArr.length - 1 ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "primary",
          onClick: $setup.recordSubmit
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("提交试卷")])),
          _: 1
        }, 8, ["onClick"])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      xs: 24,
      sm: 24,
      md: 3,
      class: "cardBig"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, null, {
        default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("div", {
          style: {
            "font-size": "14px",
            "color": "#a3a3a3"
          }
        }, "剩余时间", -1)), _createElementVNode("div", _hoisted_19, _toDisplayString($setup.timeNumTxt), 1), _cache[12] || (_cache[12] = _createElementVNode("div", {
          style: {
            "font-size": "14px",
            "color": "#a3a3a3",
            "margin-top": "10px"
          }
        }, "已答题数", -1)), _createElementVNode("div", _hoisted_20, _toDisplayString($setup.recordArr.filter(e => e.answer).length) + "/" + _toDisplayString($setup.recordArr.length), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_el_dialog, {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.dialogVisible = $event),
    width: "90%"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_21, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recordArr, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        onClick: $event => $setup.changeIndex(index),
        key: index,
        class: _normalizeClass({
          card_item: true,
          checked: $setup.questionsIndex == index
        })
      }, [item.upBool && item.answer ? (_openBlock(), _createElementBlock("img", _hoisted_23)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(index + 1), 1)], 10, _hoisted_22);
    }), 128))])]),
    _: 1
  }, 8, ["modelValue"])], 64);
}