import { defineStore } from 'pinia'
import { removeUserInfo } from '@/utils/auth'
import router from '../router'

export const userStore = defineStore('counter', {
  state: () => ({
    pageTitle: '',
    info: null,
    maps: ''
  }),
  getters: {},
  actions: {
    setPageTitle(title) {
      this.pageTitle = title
    },
    setInfo(info) {
      this.info = info
    },
    out() {
      removeUserInfo()
      this.info = ''
      setTimeout(() => {
        router.push('/')
      }, 500)
    },
    setMaps(maps) {
      this.maps = JSON.stringify(maps)
    }
  }
})
