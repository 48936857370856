import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '../assets/work.png';
const _hoisted_1 = {
  style: {
    "margin-top": "40px"
  }
};
const _hoisted_2 = {
  class: "box",
  style: {
    "min-height": "800px"
  }
};
const _hoisted_3 = {
  class: "custom-tabs-label"
};
const _hoisted_4 = {
  style: {
    "font-size": "20px"
  }
};
const _hoisted_5 = {
  style: {
    "padding": "10px",
    "box-sizing": "border-box"
  }
};
const _hoisted_6 = {
  class: "type_card"
};
const _hoisted_7 = {
  class: "card_left"
};
const _hoisted_8 = {
  key: 0,
  class: "card_right"
};
const _hoisted_9 = {
  style: {
    "color": "red"
  }
};
const _hoisted_10 = {
  style: {
    "margin-left": "4px"
  }
};
const _hoisted_11 = {
  style: {
    "color": "red"
  }
};
const _hoisted_12 = {
  key: 1,
  class: "card_right"
};
const _hoisted_13 = {
  class: "card-header"
};
const _hoisted_14 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "align-items": "center"
  }
};
const _hoisted_15 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_16 = {
  style: {
    "width": "80px"
  }
};
const _hoisted_17 = {
  style: {
    "color": "#67c23a",
    "font-size": "26px",
    "font-weight": "500",
    "margin-left": "10px"
  }
};
const _hoisted_18 = {
  style: {
    "width": "80px"
  }
};
const _hoisted_19 = {
  style: {
    "color": "red",
    "font-size": "26px",
    "font-weight": "500",
    "margin-left": "10px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 23,
      sm: 23,
      md: 18,
      lg: 18,
      xl: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tabs, {
          modelValue: $setup.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.activeName = $event),
          style: {
            "padding": "0 10px",
            "box-sizing": "border-box"
          },
          onTabChange: $setup.handleClick
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (item, i) => {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              key: i,
              name: i
            }, {
              label: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(item.name) + _toDisplayString(item.no), 1)])]),
              default: _withCtx(() => [_createVNode(_component_el_row, {
                gutter: 0
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.lessons, (e, j) => {
                  return _openBlock(), _createBlock(_component_el_col, {
                    style: {
                      "margin-bottom": "20px"
                    },
                    key: j,
                    xs: 24,
                    sm: 8,
                    md: 8,
                    lg: 6,
                    xl: 6
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)), _createTextVNode(" 作业 " + _toDisplayString(j + 1), 1)]), $setup.isTeacher ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("span", null, [_cache[3] || (_cache[3] = _createTextVNode(" 完成 ")), _createElementVNode("span", _hoisted_9, _toDisplayString(e.finishedCount || 0) + "人", 1)]), _createElementVNode("span", _hoisted_10, [_cache[4] || (_cache[4] = _createTextVNode(" 未完成 ")), _createElementVNode("span", _hoisted_11, _toDisplayString((e.studentCount || 0) - (e.finishedCount || 0)), 1), _cache[5] || (_cache[5] = _createTextVNode(" 人 "))])]), _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: $event => $setup.showStudent(item._id, e.no)
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("查看详情")])),
                      _: 2
                    }, 1032, ["onClick"])])) : _createCommentVNode("", true), !$setup.isTeacher ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", null, "共" + _toDisplayString(e.paper.questionCount) + "题", 1), !e.record ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      onClick: $event => $setup.toAmcTest(e),
                      type: "primary",
                      class: "btn"
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("开始作业")])),
                      _: 2
                    }, 1032, ["onClick"])) : e.record && !e.record.submitAt ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      class: "btn",
                      onClick: $event => $setup.toContinueResult(e),
                      type: "primary",
                      size: "large"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("继续完成")])),
                      _: 2
                    }, 1032, ["onClick"])) : _createCommentVNode("", true), e.record && e.record.submitAt ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 2,
                      type: "primary",
                      onClick: $event => $setup.toAmcResult(e)
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("查看结果")])),
                      _: 2
                    }, 1032, ["onClick"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])])]),
                    _: 2
                  }, 1024);
                }), 128))]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1032, ["name"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue", "onTabChange"])])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_el_drawer, {
    style: {
      "text-align": "left"
    },
    modelValue: $setup.drawer,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.drawer = $event),
    title: "作业完成情况",
    size: "80%"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      gutter: 10
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.students, (item, i) => {
        return _openBlock(), _createBlock(_component_el_col, {
          xs: 24,
          sm: 12,
          md: 8,
          lg: 6,
          xl: 6,
          key: i,
          span: 8
        }, {
          default: _withCtx(() => [_createVNode(_component_el_card, null, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(item.user.name), 1)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_cache[10] || (_cache[10] = _createElementVNode("span", null, "答对", -1)), _createElementVNode("span", _hoisted_17, _toDisplayString(item.rightCount), 1)]), _createElementVNode("div", _hoisted_18, [_cache[11] || (_cache[11] = _createElementVNode("span", null, "答错", -1)), _createElementVNode("span", _hoisted_19, _toDisplayString(item.errorCount), 1)])]), _createVNode(_component_el_button, {
              onClick: $event => $setup.toUserPager(item),
              disabled: !item.submitAt,
              type: "primary"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.submitAt ? '查看详情' : '未完成'), 1)]),
              _: 2
            }, 1032, ["onClick", "disabled"])])]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])], 64);
}