import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
import _imports_0 from '../assets/history.png';
import _imports_1 from '../assets/running.png';
const _hoisted_1 = {
  style: {
    "margin-top": "40px"
  }
};
const _hoisted_2 = {
  class: "box",
  style: {
    "min-height": "800px"
  }
};
const _hoisted_3 = {
  key: 0,
  class: "type_card"
};
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_6 = {
  class: "card_right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 22,
      sm: 22,
      md: 20,
      lg: 20,
      xl: 20
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 30
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paperLists, (item, index) => {
          return _openBlock(), _createBlock(_component_el_col, {
            style: {
              "margin-bottom": "20px"
            },
            key: index,
            xs: 24,
            sm: 12,
            md: 12,
            lg: 8,
            xl: 6
          }, {
            default: _withCtx(() => [item.name ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
              style: _normalizeStyle(`background:${$setup.bgcolor}`),
              class: "card_left"
            }, [$setup.bgimg == '../assets/history.png' ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), $setup.bgimg == '../assets/running.png' ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true), _createElementVNode("div", null, [_createElementVNode("div", null, _toDisplayString(item.group.name), 1), _createElementVNode("div", null, _toDisplayString(item.name), 1)])], 4), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [item.record?.submitAt ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: $event => $setup.toAmcResult(item)
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("查看结果")])),
              _: 2
            }, 1032, ["onClick"])) : _createCommentVNode("", true), !item.record || item.record.submitAt ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              onClick: $event => $setup.toAmcTest(item),
              type: "primary"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.record?.submitAt ? '再来一次' : '开始评估'), 1)]),
              _: 2
            }, 1032, ["onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
              key: 2,
              type: "primary",
              onClick: $event => $setup.toContinueResult(item)
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("继续评估")])),
              _: 2
            }, 1032, ["onClick"]))])])])) : _createCommentVNode("", true)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })])]),
      _: 1
    })]),
    _: 1
  })]);
}