import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../assets/yes.png';
import _imports_1 from '../assets/no.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  key: 0,
  class: "itemBox"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  class: "finish",
  alt: ""
};
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  class: "finish",
  alt: ""
};
const _hoisted_6 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "align-items": "center"
  }
};
const _hoisted_7 = {
  key: 0,
  style: {
    "text-align": "left"
  }
};
const _hoisted_8 = {
  style: {
    "font-size": "20px",
    "color": "#67c23a",
    "margin-right": "20px",
    "font-weight": "500"
  }
};
const _hoisted_9 = {
  style: {
    "font-size": "20px"
  }
};
const _hoisted_10 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  class: "questionTxt",
  id: "quesMath"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  style: {
    "margin-right": "10px",
    "font-weight": "500"
  }
};
const _hoisted_15 = {
  id: "answerMath",
  class: "forBox"
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  style: {
    "margin-top": "30px"
  }
};
const _hoisted_18 = {
  key: 0,
  style: {
    "text-align": "left"
  }
};
const _hoisted_19 = {
  key: 1,
  style: {
    "text-align": "left"
  }
};
const _hoisted_20 = {
  style: {
    "color": "#67c23a",
    "font-size": "28px",
    "font-weight": "500"
  }
};
const _hoisted_21 = {
  key: 2,
  style: {
    "align-items": "center",
    "text-align": "left"
  },
  id: "detailMath"
};
const _hoisted_22 = {
  style: {
    "margin-top": "30px",
    "display": "flex",
    "justify-content": "center"
  }
};
const _hoisted_23 = {
  key: 0,
  style: {
    "font-weight": "500",
    "text-align": "left"
  }
};
const _hoisted_24 = {
  style: {
    "font-size": "30px",
    "color": "#67c23a"
  }
};
const _hoisted_25 = {
  style: {
    "font-size": "26px"
  }
};
const _hoisted_26 = {
  class: "itemBox",
  style: {
    "display": "flex",
    "justify-content": "space-right",
    "align-items": "center"
  }
};
const _hoisted_27 = ["onClick"];
const _hoisted_28 = {
  key: 0,
  src: _imports_0,
  class: "finish",
  alt: ""
};
const _hoisted_29 = {
  key: 1,
  src: _imports_1,
  class: "finish",
  alt: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 10,
    class: "marginStyle"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 24,
      sm: 24,
      md: 5,
      class: "cardBig"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "min-height": "800px"
        }
      }, {
        default: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("div", {
          style: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_createElementVNode("span", {
          style: {
            "background": "#409eff",
            "width": "4px",
            "height": "30px"
          }
        }), _createElementVNode("span", {
          style: {
            "font-size": "24px",
            "margin-left": "10px",
            "font-family": "MicrosoftYaHei"
          }
        }, "答题卡")], -1)), $setup.recordArr.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recordArr, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            onClick: $event => $setup.changeIndex(index),
            key: index,
            class: _normalizeClass({
              card_item: true,
              checked: $setup.questionsIndex == index
            })
          }, [$setup.isRight(index) ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), !$setup.isRight(index) ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(index + 1), 1)], 10, _hoisted_3);
        }), 128))])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      xs: 24,
      sm: 24,
      md: 16
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "min-height": "800px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24,
          class: "cardSmall"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [$setup.resultObj ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", null, [_cache[3] || (_cache[3] = _createElementVNode("span", {
            style: {
              "font-size": "12px",
              "color": "#a3a3a3"
            }
          }, "得分：", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($setup.resultObj.record.score), 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
            style: {
              "font-size": "12px",
              "color": "#a3a3a3"
            }
          }, "总分：", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString($setup.resultObj.score), 1)])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_10, [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            onClick: $setup.nextClick
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("下一题")])),
            _: 1
          }, 8, ["onClick"]), _createVNode(_component_el_button, {
            type: "primary",
            link: "",
            onClick: _cache[0] || (_cache[0] = $event => $setup.dialogVisible = true)
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("答题卡")])),
            _: 1
          })])])]),
          _: 1
        }), $setup.questionsArr.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, "第" + _toDisplayString($setup.questionsIndex + 1) + "题. " + _toDisplayString($setup.questionsArr[$setup.questionsIndex].content), 1), $setup.questionsArr[$setup.questionsIndex].img ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "quesImg",
          style: {
            "transform": "scale(0.8)",
            "margin-bottom": "-30px",
            "margin-top": "-8px"
          },
          src: $setup.returnImgUrl($setup.questionsArr[$setup.questionsIndex].img),
          alt: ""
        }, null, 8, _hoisted_13)) : _createCommentVNode("", true), _createElementVNode("div", {
          style: {
            "margin-top": "30px",
            "padding": "0 30px",
            "box-sizing": "border-box"
          },
          class: _normalizeClass({
            imgflexbox: $setup.questionsArr[$setup.questionsIndex].options[0].img ? true : false
          })
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.questionsArr[$setup.questionsIndex].options, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["imgflexin", {
              forBox: true,
              isYes: $setup.isYes(item),
              isNo: $setup.isYes(item) ? false : $setup.isNo(item)
            }]),
            key: index,
            style: {
              "margin-bottom": "20px"
            }
          }, [_createElementVNode("div", _hoisted_14, "（" + _toDisplayString($setup.radioArr[index]) + "）", 1), _createElementVNode("div", _hoisted_15, _toDisplayString(item.value), 1), item.img ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            style: {
              "max-width": "160px"
            },
            src: $setup.returnImgUrl(item.img),
            alt: ""
          }, null, 8, _hoisted_16)) : _createCommentVNode("", true)], 2);
        }), 128))], 2)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_17, [$setup.recordArr.length && !$setup.isRight($setup.questionsIndex) ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_cache[7] || (_cache[7] = _createTextVNode(" 选择： ")), _createElementVNode("span", {
          style: _normalizeStyle([$setup.isRight($setup.questionsIndex) ? 'color:#67c23a' : 'color:red', {
            "font-size": "18px",
            "font-weight": "500"
          }])
        }, _toDisplayString($setup.getCheckIndex($setup.questionsIndex)), 5)])) : _createCommentVNode("", true), $setup.recordArr.length ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_cache[8] || (_cache[8] = _createTextVNode(" 正确答案： ")), _createElementVNode("span", _hoisted_20, _toDisplayString($setup.getRightIndex($setup.questionsIndex)), 1)])) : _createCommentVNode("", true), $setup.recordArr.length && $setup.questionsArr[$setup.questionsIndex].analysis ? (_openBlock(), _createElementBlock("div", _hoisted_21, " 解析:" + _toDisplayString($setup.questionsArr[$setup.questionsIndex].analysis), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_22, [_createVNode(_component_el_button, {
          class: "cardBig",
          size: "large",
          type: "primary",
          onClick: $setup.toHome
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("返回")])),
          _: 1
        }, 8, ["onClick"]), _createVNode(_component_el_button, {
          class: "cardSmall",
          size: "small",
          type: "primary",
          onClick: $setup.toHome
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("返回")])),
          _: 1
        }, 8, ["onClick"])])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      xs: 24,
      sm: 24,
      md: 3,
      class: "cardBig"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, null, {
        default: _withCtx(() => [$setup.resultObj ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("div", null, [_cache[11] || (_cache[11] = _createTextVNode(" 得分： ")), _createElementVNode("span", _hoisted_24, _toDisplayString($setup.resultObj.record.score), 1)]), _createElementVNode("div", null, [_cache[12] || (_cache[12] = _createTextVNode(" 总分： ")), _createElementVNode("span", _hoisted_25, _toDisplayString($setup.resultObj.score), 1)])])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_el_dialog, {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.dialogVisible = $event),
    width: "90%"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_26, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recordArr, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        onClick: $event => $setup.changeIndex(index),
        key: index,
        class: _normalizeClass({
          card_item: true,
          checked: $setup.questionsIndex == index
        })
      }, [$setup.isRight(index) ? (_openBlock(), _createElementBlock("img", _hoisted_28)) : _createCommentVNode("", true), !$setup.isRight(index) ? (_openBlock(), _createElementBlock("img", _hoisted_29)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(index + 1), 1)], 10, _hoisted_27);
    }), 128))])]),
    _: 1
  }, 8, ["modelValue"])], 64);
}