import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { getUserInfo } from '@/utils/auth';
import { classList, sclassList, homeworkDetail } from '@/api/homework';
import { userStore } from '@/store';
import { useRouter, useRoute } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = userStore();
    const students = ref([]);
    const drawer = ref(false);
    const isTeacher = getUserInfo()?.roles?.indexOf('teacher') > -1;
    const tabs = ref([]);
    const activeName = ref(0);
    const handleClick = () => {
      console.log(activeName.value);
    };
    const showStudent = (id, no) => {
      homeworkDetail({
        _id: id,
        no: no
      }).then(res => {
        if (res.success) {
          drawer.value = true;
          students.value = res.result;
        }
      });
    };
    const toContinueResult = item => {
      router.push({
        name: 'test',
        query: {
          _id: item.paper._id,
          todetail: true,
          recordId: item.record._id
        }
      });
    };
    const toAmcResult = item => {
      router.push({
        name: 'resultdetail',
        query: {
          _id: item.record._id
        }
      });
    };
    const toAmcTest = item => {
      if (store.info) {
        if (store.info.gender) {
          router.push({
            name: 'test',
            query: {
              _id: item.paper._id,
              todetail: true
            }
          });
        } else {
          router.push({
            name: 'user'
          });
        }
      } else {
        ElMessage.error('请先登录');
        setTimeout(() => {
          router.push('/login');
        }, 500);
      }
    };
    const toUserPager = item => {
      router.push({
        name: 'resultdetail',
        query: {
          _id: item._id
        }
      });
    };
    onMounted(() => {
      if (isTeacher) {
        // 获取班级列表
        classList().then(res => {
          if (res.success) {
            tabs.value = res.result;
            // if (res.result.length > 0) {
            // }
          }
        });
      } else {
        // 获取班级列表
        sclassList().then(res => {
          if (res.success) {
            tabs.value = res.result;
          }
        });
      }
    });
    return {
      drawer,
      activeName,
      students,
      bgcolor: route.query.bgcolor,
      bgimg: route.query.bgimg,
      toUserPager,
      handleClick,
      toContinueResult,
      toAmcResult,
      toAmcTest,
      showStudent,
      isTeacher,
      tabs
    };
  }
};