import "core-js/modules/esnext.iterator.map.js";
import { ref, onMounted, nextTick } from 'vue';
import { recordResultApi } from '@/api/test';
import { useRoute, useRouter } from 'vue-router';
import { getObsUrl } from '../utils/obsUrl';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const dialogVisible = ref(false);
    let questionsArr = ref([]);
    let recordArr = ref([]);
    let resultObj = ref(null);
    let questionsIndex = ref(0);
    let radioArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let radio = ref('');
    const recordId = route.query._id;
    onMounted(() => {
      testInit();
    });
    const nextClick = item => {
      if (questionsIndex.value == questionsArr.value.length - 1) {
        changeIndex(0);
      } else {
        const i = questionsIndex.value + 1;
        changeIndex(i);
      }
    };
    const changeIndex = i => {
      dialogVisible.value = false;
      questionsIndex.value = i;
      radio.value = '';
      const arr = recordArr.value;
      radio.value = arr[i].answer;
      upMathJax();
    };
    const upMathJax = item => {
      nextTick(() => {
        MathJax.texReset();
        MathJax.typesetClear();
        MathJax.typesetPromise();
      });
    };
    const getRightIndex = i => {
      let qArr = questionsArr.value;
      let oArr = qArr[i].options.map(res => res.no);
      let code = qArr[i].answer + '';
      if (oArr.indexOf(code) < 0) {
        return '未设置';
      } else {
        return radioArr[oArr.indexOf(code)];
      }
    };
    const getCheckIndex = i => {
      let qArr = questionsArr.value;
      let rArr = recordArr.value;
      let oArr = qArr[i].options.map(res => res.no);
      let code = rArr[i].answer + '';
      if (oArr.indexOf(code) < 0) {
        return '未选择';
      } else {
        return radioArr[oArr.indexOf(code)];
      }
    };
    const isRight = i => {
      let qArr = questionsArr.value;
      let rArr = recordArr.value;
      if (qArr[i].answer == rArr[i].answer) {
        return true;
      } else {
        return false;
      }
    };
    const isYes = e => {
      let qArr = questionsArr.value;
      let rArr = recordArr.value;
      let i = questionsIndex.value;
      if (e.no == qArr[i].answer) {
        return true;
      } else {
        return false;
      }
    };
    const isNo = e => {
      let qArr = questionsArr.value;
      let rArr = recordArr.value;
      let i = questionsIndex.value;
      if (!rArr[i].answer) {
        return false;
      } else {
        if (e.no != qArr[i].answer && rArr[i].answer == e.no) {
          return true;
        } else {
          return false;
        }
      }
    };
    const toHome = i => {
      router.back();
    };
    const testInit = item => {
      recordResultApi({
        _id: recordId
      }).then(res => {
        if (res.success) {
          resultObj.value = res.result;
          questionsArr.value = res.result.questions;
          recordArr.value = res.result.record.answers.map(e => {
            return Object.assign({}, e, {
              upBool: e.answer ? true : false
            });
          });
          radio.value = recordArr.value[questionsIndex.value].answer;
          upMathJax();
        }
        if (res.error && res.error.code == 'error.record_ask_continue') {}
      });
    };
    const returnImgUrl = url => {
      return getObsUrl(url);
    };
    return {
      isYes,
      isNo,
      // detailRef,
      // quesRef,
      // answerRef,
      dialogVisible,
      getRightIndex,
      getCheckIndex,
      resultObj,
      isRight,
      radio,
      radioArr,
      questionsArr,
      questionsIndex,
      recordArr,
      toHome,
      returnImgUrl,
      nextClick,
      changeIndex
    };
  }
};