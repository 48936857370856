import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
import _imports_0 from './assets/logo.jpg';
import _imports_1 from './assets/robot.png';
const _hoisted_1 = {
  class: "navbar contentTop"
};
const _hoisted_2 = {
  class: "navbarCenter"
};
const _hoisted_3 = {
  class: "flexAlignCenter"
};
const _hoisted_4 = {
  class: "pageTitle",
  style: {
    "font-size": "24px",
    "font-family": "MicrosoftYaHei"
  }
};
const _hoisted_5 = {
  class: ""
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1,
  class: "flexAlignCenter"
};
const _hoisted_8 = {
  class: "el-dropdown-link",
  style: {
    "font-size": "16px",
    "font-weight": "800",
    "display": "flex",
    "align-items": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_arrow_down = _resolveComponent("arrow-down");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.toHome && $setup.toHome(...args)),
    class: "logoIcon",
    src: _imports_0
  }), _createElementVNode("span", _hoisted_4, _toDisplayString($setup.name), 1)]), _createElementVNode("div", _hoisted_5, [!$setup.info ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_router_link, {
    to: "/login"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("登录")])),
    _: 1
  })])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.toHome && $setup.toHome(...args)),
    class: "useIcon",
    style: {
      "width": "38px",
      "height": "38px"
    },
    src: _imports_1,
    alt: ""
  }), _createVNode(_component_el_dropdown, {
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, null, {
        default: _withCtx(() => [_createVNode(_component_router_link, {
          style: {
            "width": "100%"
          },
          to: "/"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("首页")])),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, null, {
        default: _withCtx(() => [_createVNode(_component_router_link, {
          style: {
            "width": "100%"
          },
          to: "/user"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("个人中心")])),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_dropdown_item, {
        onClick: _cache[2] || (_cache[2] = $event => $setup.loginOut())
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("退出")])),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_createElementVNode("span", _hoisted_8, [_createTextVNode(" 加油，" + _toDisplayString($setup.info.name) + " ", 1), _createVNode(_component_el_icon, {
      class: "el-icon--right"
    }, {
      default: _withCtx(() => [_createVNode(_component_arrow_down)]),
      _: 1
    })])]),
    _: 1
  })]))])])]), _createVNode(_component_router_view)], 64);
}