import { ref, onMounted, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { paperlist } from '@/api/home';
// import { useStore } from 'vuex'
import { userStore } from '@/store/index';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const store = userStore();
    const paperList = ref([]);
    const activeName = ref('first');
    const toContinueResult = item => {
      router.push({
        name: 'test',
        query: {
          group: item._id,
          continueConfirmedBool: true,
          recordId: item.record._id
        }
      });
    };
    const toPapersList = (item, bgcolor, bgimg) => {
      if (store.info) {
        if (store.info.gender) {
          router.push({
            name: 'papers',
            query: {
              group: item._id,
              bgcolor: bgcolor,
              bgimg: bgimg
            }
          });
        } else {
          router.push({
            name: 'user'
          });
        }
      } else {
        ElMessage.error('请先登录');
        setTimeout(() => {
          router.push('/login');
        }, 500);
      }
    };
    const toHomeWork = () => {
      if (store.info) {
        if (store.info.gender) {
          router.push({
            name: 'homework'
          });
        } else {
          router.push({
            name: 'user'
          });
        }
      } else {
        ElMessage.error('请先登录');
        setTimeout(() => {
          router.push('/login');
        }, 500);
      }
    };
    const toAmcResult = item => {
      router.push({
        name: 'result',
        query: {
          _id: item.record._id
        }
      });
    };
    const toAmcTest = item => {
      if (store.info) {
        if (store.info.gender) {
          router.push({
            name: 'test',
            query: {
              group: item._id
            }
          });
        } else {
          router.push({
            name: 'user'
          });
        }
      } else {
        ElMessage.error('请先登录');
        setTimeout(() => {
          router.push('/login');
        }, 500);
      }
    };
    onMounted(() => {
      nextTick(() => {
        MathJax.typesetPromise();
      });
      paperlist().then(res => {
        if (res.success) {
          paperList.value = res.result;
        }
      });
    });
    return {
      activeName,
      toContinueResult,
      toAmcResult,
      toAmcTest,
      toPapersList,
      toHomeWork,
      paperList
    };
  }
};