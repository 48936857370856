import { ref, onMounted, onBeforeUnmount } from 'vue';
import { ElMessage } from 'element-plus';
import { login, getCode } from '@/api/login';
// import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { userStore } from '../store/index';
import { setUserInfo } from '@/utils/auth';
export default {
  setup() {
    const store = userStore();
    const router = useRouter();
    const locationOptions = ['Home', 'Company', 'School'];
    const ruleForm = ref({
      mobile: '',
      verify: '',
      appId: 'web'
    });
    const formRef = ref(null);
    const validatePhone = (rule, value, callback) => {
      const reg = /^1[3-9]\d{9}$/;
      if (!value) {
        return callback(new Error('手机号码不能为空'));
      }
      if (!reg.test(value)) {
        return callback(new Error('请输入正确的手机号码'));
      }
      callback();
    };
    const rules = {
      mobile: [{
        required: true,
        message: '手机号码不能为空',
        trigger: 'blur'
      }, {
        validator: validatePhone,
        trigger: 'blur'
      }],
      verify: [{
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      }, {
        length: 4,
        message: '请输入4位验证码',
        trigger: 'blur'
      }]
    };
    const timeNum = ref(60);
    let timer;
    const getCodeClick = () => {
      formRef.value.validateField(['mobile'], valid => {
        if (valid) {
          getCode(ruleForm.value).then(res => {
            if (res.success) {
              timer = setInterval(() => {
                if (timeNum.value == 0) {
                  timeNum.value = 60;
                  clearInterval(timer);
                } else {
                  timeNum.value = timeNum.value - 1;
                }
              }, 1000); // 1000毫秒，即1秒
              ElMessage.success('发送成功');
            }
          });
        }
      });
    };
    const submitForm = () => {
      formRef.value.validate(valid => {
        if (valid) {
          login(ruleForm.value).then(res => {
            if (res.success) {
              ElMessage.success('登录成功');
              store.setInfo(res.result);
              setUserInfo(res.result);
              setTimeout(() => router.push('/'), 100);
            }
          });
        } else {
          return false;
        }
      });
    };
    const backPage = () => {
      router.back();
    };
    onMounted(() => {
      setTimeout(() => {}, 10);
    });
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    return {
      formRef,
      rules,
      timeNum,
      ruleForm,
      submitForm,
      getCodeClick,
      backPage
    };
  }
};