import { ArrowDown } from '@element-plus/icons-vue';
// import { useStore } from 'vuex'
import { onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { userStore } from './store/index';
export default {
  components: {
    ArrowDown
  },
  setup() {
    const store = userStore();
    const router = useRouter();
    const loginOut = () => {
      store.out();
    };
    const toHome = () => {
      router.push('/');
    };
    onMounted(() => {});
    return {
      info: computed(() => store.info),
      name: computed(() => store.pageTitle),
      toHome,
      loginOut
    };
  }
};