import { ref, onMounted, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { userUpDate, userDetail } from '@/api/user';
import { setUserInfo } from '@/utils/auth';
import { login } from '@/api/login';
import { userStore } from '@/store';
import { getDictionary } from '@/api/login';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const store = userStore();
    const router = useRouter();
    const gradeOptions = ref();
    const ruleForm = ref({
      name: '',
      school: '',
      grade: '',
      gender: ''
    });
    const formRef = ref(null);
    const validateUsername = (rule, value, callback) => {
      if (value.indexOf('用户') > -1) {
        callback(new Error('请输入真实姓名'));
      } else {
        callback();
      }
    };
    const rules = {
      name: [{
        required: true,
        message: '请输入用户名',
        trigger: 'blur'
      }],
      school: [{
        required: true,
        message: '请输入学校',
        trigger: 'blur'
      }],
      grade: [{
        required: true,
        message: '请选择年级',
        trigger: 'change'
      }],
      gender: [{
        required: true,
        message: '请选择性别',
        trigger: 'change'
      }]
    };
    const backPage = () => {
      router.back();
    };
    const submitForm = () => {
      formRef.value.validate(valid => {
        if (valid) {
          userUpDate(ruleForm.value).then(res => {
            if (res.success) {
              ElMessage.success('修改成功');
              login().then(ress => {
                store.setInfo(ress.result);
                setUserInfo(ress.result);
                nextTick(() => router.back());
              });
            }
          });
        } else {
          return false;
        }
      });
    };
    onMounted(() => {
      getDictionary().then(res => {
        if (res.success) {
          gradeOptions.value = res.result.grade;
        }
      });
      userDetail().then(res => {
        ruleForm.value = Object.assign({}, res.result, {
          grade: res.result?.grade ? res.result.grade.value : '',
          name: res.result?.name.indexOf('用户') > -1 ? '' : res.result?.name
        });
      });
      setTimeout(() => {}, 10);
    });
    return {
      formRef,
      rules,
      ruleForm,
      submitForm,
      gradeOptions,
      backPage
    };
  }
};