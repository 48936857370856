import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const UserInfoKey = 'User_Info'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  try {
    return JSON.parse(Cookies.get(UserInfoKey))
  } catch (e) {
    return null
  }
}

export function setUserInfo(info) {
  try {
    return Cookies.set(UserInfoKey, JSON.stringify(info))
  } catch (e) {
    return null
  }
}

export function removeUserInfo() {
  return Cookies.remove(UserInfoKey)
}
