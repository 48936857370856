import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '../assets/amcbg.png';
import _imports_1 from '../assets/amctitle.png';
import _imports_2 from '../assets/history.png';
import _imports_3 from '../assets/running.png';
const _hoisted_1 = {
  class: "box"
};
const _hoisted_2 = {
  style: {
    "text-align": "left",
    "margin-bottom": "20px"
  }
};
const _hoisted_3 = {
  key: 0,
  ref: "innerRef"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 3,
  class: "scoreTxt",
  style: {
    "color": "#95d475"
  }
};
const _hoisted_6 = {
  key: 4,
  class: "scoreTxt",
  style: {
    "color": "red"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
    style: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 23,
      sm: 23,
      md: 18,
      lg: 18,
      xl: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        style: {
          "min-height": "800px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            style: {
              "font-size": "20px",
              "margin-bottom": "20px"
            },
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
              style: {
                "width": "80%"
              },
              src: _imports_0,
              alt: ""
            }, null, -1), _createElementVNode("div", {
              style: {
                "text-align": "left",
                "width": "80%",
                "margin-left": "10%"
              }
            }, [_createElementVNode("div", {
              style: {
                "font-weight": "800",
                "font-size": "25px",
                "margin-top": "20px"
              }
            }, "2025年AMC8中国区"), _createElementVNode("div", {
              style: {
                "margin-top": "10px",
                "font-weight": "500"
              }
            }, "报名截止时间："), _createElementVNode("div", null, "2025年1月12日"), _createElementVNode("div", {
              style: {
                "margin-top": "10px",
                "font-weight": "500"
              }
            }, "竞赛时间："), _createElementVNode("div", null, "2025年1月23日(周五)"), _createElementVNode("div", null, "10:00-10:40(中国时间)"), _createElementVNode("div", {
              style: {
                "margin-top": "10px",
                "font-weight": "500"
              }
            }, "竞赛资格："), _createElementVNode("div", null, "8年级且14.5岁以下")], -1)])),
            _: 1
          }), _createVNode(_component_el_col, {
            xs: 24,
            sm: 24,
            md: 18,
            lg: 18,
            xl: 18
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[6] || (_cache[6] = _createElementVNode("img", {
              style: {
                "width": "200px"
              },
              src: _imports_1,
              alt: ""
            }, null, -1)), $setup.paperList.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paperList.slice(0, 1), (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                key: index,
                class: "type_cardA"
              }, [_createElementVNode("div", null, [item.name ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.name), 1)) : _createCommentVNode("", true)]), item.status == 'editing' ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "info",
                plain: "",
                disabled: ""
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("待上线")])),
                _: 1
              })) : !item.record ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                onClick: $event => $setup.toAmcTest(item),
                type: "primary",
                class: "btn"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("去答题")])),
                _: 2
              }, 1032, ["onClick"])) : item.record && !item.record.submitAt ? (_openBlock(), _createBlock(_component_el_button, {
                key: 2,
                class: "btn",
                onClick: $event => $setup.toContinueResult(item),
                type: "primary",
                size: "large"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("继续评估")])),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true), item.record && item.record.submitAt && item.record.passed ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.record.score) + " 分", 1)) : _createCommentVNode("", true), item.record && item.record.submitAt && !item.record.passed ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.record.score) + " 分", 1)) : _createCommentVNode("", true)]);
            }), 128))], 512)) : _createCommentVNode("", true)]), _createVNode(_component_el_row, {
              gutter: 20
            }, {
              default: _withCtx(() => [$setup.paperList.length && $setup.paperList.length > 1 ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                xs: 24,
                sm: 24,
                md: 12,
                lg: 12,
                xl: 12,
                style: {
                  "margin-bottom": "10px"
                }
              }, {
                default: _withCtx(() => [_createElementVNode("div", {
                  class: "testbg",
                  style: {
                    "background": "#ffb15f"
                  },
                  onClick: _cache[0] || (_cache[0] = $event => $setup.toPapersList($setup.paperList[1], '#ffb15f', '../assets/history.png'))
                }, [_cache[7] || (_cache[7] = _createElementVNode("img", {
                  src: _imports_2,
                  alt: ""
                }, null, -1)), _createTextVNode(" " + _toDisplayString($setup.paperList[1].name), 1)])]),
                _: 1
              })) : _createCommentVNode("", true), $setup.paperList.length && $setup.paperList.length > 2 ? (_openBlock(), _createBlock(_component_el_col, {
                key: 1,
                xs: 24,
                sm: 24,
                md: 12,
                lg: 12,
                xl: 12,
                style: {
                  "margin-bottom": "10px"
                }
              }, {
                default: _withCtx(() => [_createElementVNode("div", {
                  class: "testbg",
                  style: {
                    "background": "#f96a6a"
                  },
                  onClick: _cache[1] || (_cache[1] = $event => $setup.toPapersList($setup.paperList[2], '#f96a6a', '../assets/running.png'))
                }, [_cache[8] || (_cache[8] = _createElementVNode("img", {
                  style: {
                    "width": "40px",
                    "height": "40px"
                  },
                  src: _imports_3,
                  alt: ""
                }, null, -1)), _createTextVNode(" " + _toDisplayString($setup.paperList[2].name), 1)])]),
                _: 1
              })) : _createCommentVNode("", true)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}