import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { paperlists } from '@/api/home';
import { userStore } from '@/store';
import { useRouter, useRoute } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = userStore();
    const paperLists = ref([]);
    const toContinueResult = item => {
      router.push({
        name: 'test',
        query: {
          _id: item._id,
          todetail: true,
          recordId: item.record._id
        }
      });
    };
    const toAmcResult = item => {
      router.push({
        name: 'resultdetail',
        query: {
          _id: item.record._id
        }
      });
    };
    const toAmcTest = item => {
      if (store.info) {
        if (store.info.gender) {
          router.push({
            name: 'test',
            query: {
              _id: item._id,
              todetail: true
            }
          });
        } else {
          router.push({
            name: 'user'
          });
        }
      } else {
        ElMessage.error('请先登录');
        setTimeout(() => {
          router.push('/login');
        }, 500);
      }
    };
    onMounted(() => {
      paperlists({
        group: route.query.group
      }).then(res => {
        if (res.success) {
          paperLists.value = res.result;
        }
      });
    });
    return {
      bgcolor: route.query.bgcolor,
      bgimg: route.query.bgimg,
      toContinueResult,
      toAmcResult,
      toAmcTest,
      paperLists
    };
  }
};