import { ref, onMounted } from 'vue';
import { recordResultApi } from '@/api/test';
import { useRouter, useRoute } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const record = ref();
    const recordId = route.query._id;
    const backPage = () => {
      router.back();
    };
    onMounted(() => {
      testInit({});
    });
    const testInit = item => {
      recordResultApi({
        _id: recordId
      }).then(res => {
        if (res.success) {
          record.value = res.result;
        }
      });
    };
    return {
      record
    };
  }
};